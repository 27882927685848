import React from 'react';
import QouteIcon from '../../Assets/images/Quote.svg';
import VectorIcon from '../../Assets/images/Vector-1.svg';
import VectorIcon2 from '../../Assets/images/Group 767.svg';
import Image from '../../Assets/images/Rectangle 11.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FirstSection = () => {
  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
  };

  const images = importAll(
    require.context('../../Assets/Company slider', false, /\.(png|jpe?g|svg)$/),
  );

  function importAll(r) {
    let images = {};
    let arr = [];
    // r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    r.keys().forEach((item, index) => {
      arr.push(r(item));
    });
    return arr;
  }

  console.log({ images });

  return (
    <div className="relative lg:pb-20 ">
      <main className="container">
        <section className="flex flex-col-reverse md:grid md:grid-cols-3 gap-5   pt-10">
          <div className="col-span-1">
            <div>
              <img src={QouteIcon} alt="" className="h-4" />
              <h1 className="text-primary text-3xl font-bold">
                Humble Beginnings
              </h1>
              <p className="italic text-secondary text-lg">
                Where it all started.
              </p>
              <p className="mt-2 ">
                EFS LOGISTICS was established in the early months of 1996 with a
                modest team of only 5 employees in Jeddah, Saudi Arabia. Since
                then, EFS has experienced remarkable growth and has emerged as a
                world-class provider of innovative logistics and freight
                forwarding services and solutions, expanding its operations
                across various regions in the Kingdom. Our commitment to
                excellence is demonstrated through our dedicated and experienced
                professionals who consistently deliver tailored logistics
                solutions to meet your specific requirements.
              </p>

              <img
                src={VectorIcon}
                className={'absolute left-0 bottom-0 h-60 md:block hidden'}
                alt=""
              />
            </div>
          </div>

          <div className="col-span-2">
            <div className="md:w-10/12	lg:w-8/12 ml-auto block">
              <Slider {...settings}>
                {images.map((item) => (
                  <div className="flex justify-end relative ">
                    <img src={item.default} className="w-full" alt="" />
                    <img
                      src={VectorIcon2}
                      className="absolute right-0 bottom-0 "
                      alt=""
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </section>
          <p className=" mt-2 pb-20">
            One of our key strengths is our in-house infrastructure, which we
            have developed over the years. This allows us to provide services
            directly without relying on outsourcing. At every stage, EFS
            actively supports Saudi Arabia's National Vision 2030, aligning our
            operations with the country's strategic goals. As a result, our
            company embodies a truly Saudi identity. <br /> <br />
            We take pride in our team and the comprehensive infrastructure we
            have established, enabling us to deliver high-quality services that
            contribute to the success of our clients and the overall development
            of the logistics industry in Saudi Arabia. <br />
            <br />
            Thank you for choosing EFS LOGISTICS as your trusted logistics
            partner.
          </p>
      </main>
    </div>
  );
};

export default FirstSection;
